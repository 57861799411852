import React from "react";
import { graphql } from "gatsby";

import { getImage } from "gatsby-plugin-image";

import Layout from "../layout/layout";
import Carousel from "../components/carousel";
import OutreachContent from "../components/outreach-content";

const Outreach = ({ data }) => {
  let idx = 0;
  const imgSources = data.allFile.nodes.map((n) => ({
    idx: ++idx,
    name: n.name,
    image : getImage(n),
    webp: n.childImageSharp.gatsbyImageData.images.sources[0].srcSet,
    orig: n.childImageSharp.gatsbyImageData.images.fallback.src,
  }));
  return (
    <Layout>
      <div style={{ marginTop: "100px" }}>
        <Carousel sources={imgSources} />
      </div>
      <OutreachContent />
    </Layout>
  );
};

export const query = graphql`query OutreachPhotosQuery {
  allFile(
    filter: {sourceInstanceName: {eq: "outreach-repo"}, relativeDirectory: {eq: "photographs"}}
  ) {
    nodes {
      name
      childImageSharp {
        gatsbyImageData(height: 900, placeholder: BLURRED, layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
}
`;
export default Outreach;
