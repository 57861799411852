import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Faq from "../components/faq";

const WorkshopFaq = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx(
        filter: {
          fileAbsolutePath: {
            regex: "/(/gatsby-source-git/outreach-repo/faq/Q[0-9]+/index.mdx)/"
          }
        }
        sort: { order: ASC, fields: [frontmatter___title] }
      ) {
        nodes {
          frontmatter {
            title
          }
          body
        }
      }
    }
  `);
  console.log('data::', data)
  console.log('data.allMdx::', data.allMdx)

  return <Faq mdxNodes={data.allMdx.nodes} />;
};

export default WorkshopFaq;
