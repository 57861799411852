import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export const WorkshopData = () => {
  const fields = [
    // { key: "Institute", label: "Institute" },
    { key: "College_Name", label: "College Name" },
    { key: "Location", label: "Location" },
    { key: "Version", label: "Version" },
    // { key: "Date", label: "Date" },
    {
      key: "Date",
      label: "Date",
      formatter: (str) => new Date(str).toDateString(),
    },
    { key: "Participants_Attended", label: "Participants Attended" },
    // { key: "No_of_Sessions", label: "No of Sessions" },
    // { key: "Session_Duration", label: "Session Duration" },
    { key: "Usage", label: "Usage" },
  ];

  const data = useStaticQuery(graphql`
    {
      allWsXlsxInstituteWiseData {
        nodes {
          Institute
          College_Name
          Location
          Version
          Date
          Participants_Attended
          Session_Duration
          Usage
        }
      }
    }
  `);
  const sheetData = data.allWsXlsxInstituteWiseData.nodes;

  return (
    <div className="table-container">
      {sheetData.length > 0 ? (
        <table className="table is-bordered is-hoverable is-striped is-narrow is-fullwidth">
          <thead>
            <tr>
              <th>S.No.</th>
              {fields.map((f, idx) => (
                <th key={idx}>{f.label}</th>
              ))}
            </tr>
          </thead>
          <tfoot>
            <tr>
              {/* <th>S.No.</th>
              {fields.map((f, idx) => (
                <th key={idx}>{f.label}</th>
              ))} */}
            </tr>
          </tfoot>
          <tbody>
            {sheetData.map((n, idxRow) => (
              <tr key={idxRow + 1}>
                <th>{idxRow + 1}</th>
                {fields.map((f, idxCol) => (
                  //   <th>{n[f.key]}</th>
                  <td key={idxCol}>
                    {f.formatter ? f.formatter(n[f.key]) : n[f.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No Workshop data available</p>
      )}
    </div>
  );
};

export default WorkshopData;
