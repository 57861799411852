import React, { useEffect, useState } from "react";

import NodalCenters from "./workshop-nodal-centers";
import WorkshopData from "./workshop-data";
import WorkshopProcess from "./workshop-process";
import WorkshopFaq from "./workshop-faq";
import * as styles from "../scss/components/outreach-content.module.scss";

export const OutreachContent = () => {
  const tabs = [
    { id: "nc", label: "Nodal Centers", component: NodalCenters },
    { id: "workshop", label: "Workshops Organized", component: WorkshopData },
    { id: "request", label: "Request a Workshop", component: WorkshopProcess },
    { id: "faq", label: "FAQs", component: WorkshopFaq },
  ];
  const [selectedTab, setSelectedTab] = useState(0);

  const getTabIdxFromHash = (hash) => {
    const id = hash.substr(1);
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].id === id) return i;
    }
    return 0;
  };

  // const tabIdx =
  //   typeof window !== "undefined" ? getTabIdxFromHash(window.location.hash) : 0;
  // const [selectedTab, setSelectedTab] = useState(tabIdx);
  useEffect(() => setSelectedTab(getTabIdxFromHash(window.location.hash)), []);

  return (
    <div className={styles.contentPanel}>
      <div className="tabs is-toggle is-centered is-fullwidth ">
        <ul>
          {tabs.map((t, idx) => (
            <li
              className={selectedTab === idx ? "is-active" : ""}
              key={idx}
              id={t.id}
            >
              <a onClick={() => setSelectedTab(idx)}>{t.label}</a>
            </li>
          ))}
        </ul>
      </div>
      {tabs[selectedTab].component()}
    </div>
  );
};

export default OutreachContent;
