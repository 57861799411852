import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Documentation from "../components/documentation";

const WorkshopProcess = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: {
            regex: "*/outreach-repo/workshop-process/workshop-process.md/i"
          }
        }
      ) {
        nodes {
          headings {
            depth
            id
            value
          }
          html
          fileAbsolutePath
        }
      }
    }
  `);

  return data.allMarkdownRemark.nodes.length > 0 ? (
    <div style={{ maxWidth: "70vw", margin: "auto" }}>
      {/* dangerouslySetInnerHTML= {{ __html: data.allMarkdownRemark.nodes[0].html }} */}
      <Documentation markDownNode={data.allMarkdownRemark.nodes[0]} />
    </div>
  ) : (
    <p>No Process Available</p>
  );
};

export default WorkshopProcess;
