import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export const NodalCenters = () => {
  const fields = [
    // { key: "Institute", label: "Institute" },
    { key: "Name", label: "Name" },
    { key: "Location", label: "Location" },
    { key: "Pincode", label: "Pincode" },
    // { key: "Status", label: "Status" },
    { key: "Actions", label: "Actions" },
  ];

  const data = useStaticQuery(graphql`
    {
      allNsXlsxNcList25Nov2020 {
        nodes {
          Name
          Location
          Pincode
          Actions
        }
      }
    }
  `);
  const sheetData = data.allNsXlsxNcList25Nov2020.nodes;

  return (
    <div className="table-container">
      {sheetData.length > 0 ? (
        <table className="table is-bordered is-hoverable is-striped is-narrow is-fullwidth">
          <thead>
            <tr>
              <th>S.No.</th>
              {fields.map((f, idx) => (
                <th key={idx}>{f.label}</th>
              ))}
            </tr>
          </thead>
          <tfoot>
            <tr>
              {/* <th>S.No.</th>
              {fields.map((f, idx) => (
                <th key={idx}>{f.label}</th>
              ))} */}
            </tr>
          </tfoot>
          <tbody>
            {sheetData.map((n, idxRow) => (
              <tr key={idxRow + 1}>
                <th>{idxRow + 1}</th>
                {fields.map((f, idxCol) => (
                  <td key={idxCol}>
                    {f.formatter ? f.formatter(n[f.key]) : n[f.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No Nodal Center data available</p>
      )}
    </div>
  );
};

export default NodalCenters;
